<template>
  <div class="container">
    <calendar
      :attributes="attr"
      :rows="2"
      is-expanded
      @dayclick="dateSelected($event)"
    />

    <!-- <div class="row g-5">
      <div class="col-md-6">
        <div class="d-flex h-100 align-items-center">
          <div
            class="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
            <div class="card w-100">
              <div class="card-body text-center pt-0">
                <img alt="Logo" src="/media/misc/sun.png" class="h-150px logo" />
                <h2>Lunch</h2>
                <a
                  href="#"
                  class="btn btn-sm btn-primary"
                  @click="sessionSelected('LUNCH')">
                  Select
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex h-100 align-items-center">
          <div
            class="w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10">
            <div class="card w-100">
              <div class="card-body text-center pt-0">
                <img alt="Logo" src="/media/misc/evening.png" class="h-150px logo" />
                <h2>Dinner</h2>
                <a
                  href="#"
                  class="btn btn-sm btn-primary"
                  @click="sessionSelected('DINNER')">
                  Select
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import { format, parse } from "date-fns";
import { postAsyncOptimizeTrips } from "@/api/optimize.api";
import Calendar from "v-calendar/lib/components/calendar.umd";

export default {
  components: { Calendar },
  setup(props, context) {
    const dateSelected = async (event) => {
      const date = format(
        parse(event.id, "yyyy-MM-dd", new Date()),
        "yyyy-MM-dd"
      );
      await postAsyncOptimizeTrips(date);

      const router = context.root.$router;
      router.push({
        name: "Optimize",
      });
    };

    const attr = [
      {
        key: "today",
        highlight: true,
        dates: new Date(),
      },
    ];

    return {
      attr,
      dateSelected,
    };
  },
};
</script>
